var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_verified" },
    [
      _c(
        "vs-popup",
        {
          staticClass: "_verified __popup",
          attrs: {
            "button-close-hidden": "",
            active: "",
            title: _vm.$t("atencao-0"),
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col gap-4 align-middle justify-center" },
            [
              _c("div", { staticClass: "w-full h-1" }),
              _c(
                "label",
                {
                  class: [
                    "w-full text-center font-bold text-lg",
                    `text-${_vm.color}`,
                  ],
                },
                [_vm._v(_vm._s(_vm.$t("conta-ativada-com-sucesso")))]
              ),
              _c("div", { staticClass: "w-full h-1" }),
              _c(
                "div",
                { staticClass: "w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right",
                      attrs: { color: _vm.color },
                      on: { click: _vm.procceed },
                    },
                    [_vm._v("OK")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }